.wt-round-header-segment {
  @apply rounded-full bg-base-200 px-8 py-3 text-lg;
}

.wt-round-steps {
  @apply text-xl font-bold flex items-center w-full;
}

.wt-round-step {
  @apply transition-all duration-700 ease-in-out;
  @apply after:content-[''] after:w-[30px] after:h-1 after:border-white after:border-2 after:inline-block;
  }

.wt-round-step.active {
  @apply after:content-[''] after:w-[30px] after:h-1 after:border-highlight after:dark:border-highlight-dark after:border-2 after:inline-block;
}


.wt-round-step:last-child {
  @apply after:hidden;
}

.wt-round-step {
  @apply flex w-full items-center;
}
.wt-round-step span {
  @apply flex items-center justify-center text-base size-8 bg-white text-highlight dark:text-highlight-dark rounded-full shrink-0;
}
[data-site-mode="whentaken-movies"] .wt-round-step span {
  @apply text-primary-content dark:text-primary-content;
}
.wt-round-step.active span {
  @apply bg-highlight dark:bg-highlight-dark text-primary-content cursor-pointer hover:bg-highlight/70 hover:dark:bg-highlight-dark/70;
}

.wt-round-header-label {
  @apply mr-4 w-14 text-base;
}

.wt-round-header .steps {
  @apply -translate-x-2;
}

.wt-round-step.current {
  @apply after:content-[''] after:w-[30px] after:h-1 after:border-2 after:inline-block;
}
.wt-round-step.current:last-child {
  @apply after:hidden;
}
.wt-round-step.current span {
  @apply cursor-pointer scale-125 transition-all duration-150 ease-linear;
}

.wt-round-step.next-round span {
  @apply cursor-pointer;
}
.wt-round-step.current.results span {
  @apply bg-highlight dark:bg-highlight-dark text-primary-content border-double border-4 border-white;
}

.wt-round-step.active.most-recent {
  @apply after:content-[''] after:w-[30px] after:h-1 after:border-white after:border-2 after:inline-block;
  }


  .wt-round-step.active.most-recent:last-child {
    @apply after:hidden;
}