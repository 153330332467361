:root {
  --ssc: var(--fallback-su, oklch(var(--su) / 0.6));
  --ssb: 0 0 6px 4px var(--ssc)
}
.slider {
  -webkit-appearance: none;
  appearance: none;
  @apply h-1.5 bg-[#645C70] movies:bg-[#666666] rounded-lg w-full;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  @apply w-6 h-6 relative bg-primary text-white rounded-full border-[2px] border-solid border-white;
}

.slider.noYearChosen::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  @apply w-6 h-6 relative bg-[#978FA3] movies:bg-[#BAB7AB] text-white rounded-full border-[2px] border-solid border-white;
}
.selector {
  position: absolute;
  left: 50%;
  top: -50%;
  opacity: 0;

  @apply transition duration-200 ease-linear -translate-x-1/2 scale-0;
  pointer-events: none;
}

.selectValue {
  @apply px-2.5 py-1.5 text-black leading-none flex justify-center items-center bg-white rounded-lg bottom-0 -translate-y-full;
  text-align: center;
}

.selectValue.error {
    --ssc: var(--fallback-er, oklch(var(--er) / 1));
    --ssb: 0 0 6px 4px var(--ssc)
}

.sliderDivider {
  @apply h-8;
}
.selectValue {
  @apply text-xl w-20;
}
.selectValue.noYearChosen {
  @apply text-[#978FA3];
}
@media screen and (min-width: 768px) {
  .sliderDivider {
    @apply h-12;
  }

  .selectValue {
    @apply text-xl w-20;
  }

  .slider::-webkit-slider-thumb {
    @apply w-7 h-7;
  }
}

@media screen and (max-width: 480px) {

  :root {
    --ssc: var(--fallback-su, oklch(var(--su) / 0.6));
    --ssb: 0 0 3px 2px var(--ssc)
  }

  .sliderDivider {
    @apply h-10;
  }

  .selectValue {
    @apply text-base w-20;
  }

  .slider::-webkit-slider-thumb {
    @apply w-5 h-5;
  }
}
