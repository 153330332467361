.info-blocks {
  @apply w-full md:max-w-screen-lg h-auto grid grid-cols-1 lg:grid-cols-2 gap-4;
}

.info-blocks .info-block:nth-child(1) .info-step:after {
  content: "1";
}

.info-blocks .info-block:nth-child(3) .info-step:after {
  content: "2";
}

.info-blocks .info-block:nth-child(5) .info-step:after {
  content: "3";
}

.info-blocks .info-block:nth-child(7) .info-step:after {
  content: "4";
}

.info-block {
  @apply inline-flex flex-col gap-5 md:p-3;
}

.info-step {
  @apply relative inline-flex w-8 h-8 text-lg font-bold bg-white text-accent shrink grow basis-0 flex-col items-center justify-center gap-2.5 self-stretch rounded-full;
}

[data-site-mode="whentaken-movies"] .info-step {
  @apply text-primary-content dark:text-primary-content;
}

.info-step:after {
  @apply relative w-8 h-8 flex justify-center items-center;
}

.info-description {
  @apply w-full text-base font-normal leading-tight;
}

.info-content {
  @apply relative flex justify-center items-center;
}

.info-header {
  @apply flex items-center w-full flex-row justify-center gap-4 md:gap-5 lg:gap-6;
}